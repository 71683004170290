export const index_name = process.env.GATSBY_ALGOLIA_INDEX_NAME;

export const currency = process.env.GATSBY_CURRENCY;
export const country = "London";
export const defaultArea = process.env.GATSBY_DEFAULT_AREA ? process.env.GATSBY_DEFAULT_AREA : "London";
export const defaultArea_Slug = defaultArea.toLowerCase().replace(/ /g,'-');

export const defaultArea_Newhomes = process.env.GATSBY_DEFAULT_AREA_NEWHOMES ? process.env.GATSBY_DEFAULT_AREA_NEWHOMES : "London";
export const defaultArea_Newhomes_Slug = defaultArea_Newhomes.toLowerCase().replace(/ /g,'-');

export const defaultResult_Link = "/property/to-rent/in-"+defaultArea_Slug+"/";

export const site_name = process.env.GATSBY_SITE_NAME;
export const hasMyaccount = (process.env.GATSBY_MYACCOUNT || null) === '1' ? true : false;
export const postCodeAddressAPI = "ncZgV7m79UipxWpivISlAw37900";

export const IsAllAgentReview = true;
export const Default_View = "List"
export const slider_count = 3;
export const news_slider_count = 3;

export const search_result_sidebar = true;

export const SimilarProperty_FullWdth = true;

export const currency_converter = true;

export const viewing_btn_text = "Enquire";

export const search_box_placeholder = "Enter a location e.g. London or SE1";

export const footerVatDetails = "Chase Evans Residential Limited is registered in London.<br />Registered office address: 70 St. Mary Axe, London, EC3A 8BE.<br />Registered company number: 03664031. VAT Number: 769 9784 34<br />Part of Lomond";

export const Mail_Vars = {
    fb_link: "https://www.facebook.com/ChaseEvansLtd/",
    tw_link: "https://twitter.com/ChaseEvansLtd",
    company_phone: "(0)20 3603 8692",
    available_time: "We're available from 9am - 7pm Monday to Thursday, 9am - 6pm Friday, 10am - 5pm Saturday",
    address: "70 St. Mary Axe, London, EC3A 8BE."
}

// export const PageLinks = {
//     about: "about-brandvaughan",
//     general_enquiry: "general-enquiry",
//     news_insight: `about-brandvaughan/insights`,
//     branch_finder: "about-brandvaughan/branch-finder",
//     area_cover: "about-brandvaughan/areas-we-cover",
//     branch_contact: "contact-us",
//     team_contact: "contact-us",
//     teams: "about-brandvaughan/meet-the-team",
//     request_valuation: "property-valuation",
//     privacy_policy: "privacy-policy",
//     book_a_viewing: "book-a-viewing",
//     book_a_valuation: "property-valuation/home-visit-valuation",
//     send_to_friend: "send-to-friend",
//     property_to_rent: "property/to-rent",
//     property_for_sale: "property/for-sale",
//     reviews: "about-brandvaughan/customer-reviews",
//     property_management: "landlords/property-management",
//     financial_services: `financial-services`
// }

export const PropertySearchURL = {
    residential: {
        sales:"/property/for-sale/",
        lettings:"/property/to-rent/",
        newhomes: "/new-homes/for-sale/",
        newdevelopments: "/new-developments/for-sale/",
        investments: "/property-investments/for-sale/",
        students: "/property-students/to-rent/"
    },
    commercial: {
        sales:"/commercial/for-sale/",
        lettings:"/commercial/to-rent/",
    },
    auction: {
        sales:"/auctions/for-sale/"
    }
}

export const PropertyDetailsURL = {
    residential: {
        sales:"/property-for-sale/",
        lettings:"/property-to-rent/",
        newhomes:"/new-homes-for-sale/",
        newdevelopments:"/new-developments-for-sale/"
    },
    commercial: {
        sales:"/commercial-for-sale/",
        lettings:"/commercial-to-rent/",
    },
    auction: {
        sales:"/auction-for-sale/"
    }
}

export const SearchTitle = {
    residential: {
        sales:"Properties for sale",
        lettings:"Properties to rent",
        newhomes:"New homes",
        newdevelopments:"New developments",
        investments: "Investment properties for sale",
        students:"Student properties for sale",
    },
    commercial: {
        sales:"Commercial properties for sale",
        lettings:"Commercial properties to rent",
    },
    auction: {
        sales:"Auction properties for sale"
    }
}

export const PopularSearch_Areas = [ 
    { value: "lonodon", label: "London" },
    { value: "canary-wharf", label: "Canary Wharf" },
    { value: "elephant-and-castle", label: "Elephant and Castle" },
    { value: "royal-docks", label: "Royal Docks" }   
]

export const PopularSearch_Types = [ 
    { value: "house", label: "Houses" },
    { value: "apartment", label: "Apartments" },
    { value: "bungalow", label: "Bungalows" },
    { value: "maisonette", label: "Maisonettes" }   
]

export const sortByList = [ 
    { value: "Newest", label: "Newest" },
    { value: "Oldest", label: "Oldest" },
    { value: "HighestPrice", label: "Highest Price" },
    { value: "LowestPrice", label: "Lowest Price" }   
]

export const SearchType = {
    sales:"For Sale",
    lettings:"To Rent",
    newhomes:"New homes",
    investments:"Investments",
    students:"Students",
    commercial_sales:"Commercial Sales",
    commercial_lettings:"Commercial Lettings",
    auction_sales:"Auctions",
}

export const FurnishedType = [    
    { value: 'furnished', label: 'Furnished' },
    { value: 'unfurnished', label: 'Unfurnished' },
    { value: 'part-furnished', label: 'Part furnished' },
]

export const leadooPageSlot = {
    sell_property:"Sellers",
    landlords:"Landlords",
    sales:"Sale listings",
    lettings:"Rental listing",
    branch_finder:"Branch finder",
    general_enquiry:"General enquiry",
    valuation:"Valuation",
    home:"Valuation"
}